import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
	const tableBorderStyles = {
		borderRadius: '20px',
		border: `1px solid ${theme.palette.common.black}`,
		padding: theme.spacing(2, 8),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(1, 3),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1, 2),
		},
	};

	return {
		styledTableWrap: {
			...tableBorderStyles,
			boxShadow: '0px 4px 30px #FAD8BA',
			border: `1px solid ${theme.palette.primary.main}`,
		},
		tableWrap: {
			overflowX: 'auto',
			paddingBottom: theme.spacing(1),

			'&::-webkit-scrollbar': {
				height: 4,
			},
			'&::-webkit-scrollbar-track': {
				backgroundColor: 'rgba(0, 0, 0, 0.05)',
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: 'rgba(0, 0, 0, 0.5)',
				borderRadius: 5,
			},
		},
		table: {
			width: '100%',
			borderCollapse: 'collapse',

			'& th, td': {
				padding: theme.spacing(2),
				[theme.breakpoints.down('sm')]: {
					padding: theme.spacing(1),
					fontSize: '14px',
				},
			},
		},
		tableRowBorder: {
			'& tr': {
				borderBottom: `1px solid ${theme.palette.common.grey}`,
			},
			'& tbody tr:last-child': {
				border: 'none',
			},
		},
		tableColumnBorder: {
			'& td + td, th + th': {
				borderLeft: `1px solid ${theme.palette.common.grey}`,
			},
		},

		text_center: {
			textAlign: 'center',
		},
		text_left: {
			textAlign: 'left',
		},
		text_right: {
			textAlign: 'right',
		},
		tableBorder: {
			...tableBorderStyles,
		},
	};
});

export default useStyles;
