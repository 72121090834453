import { Done } from '@mui/icons-material';
import PropTypes from 'prop-types';
import useStyles from './style';

export default function CustomListItem({ value }) {
	const { classes } = useStyles();
	return (
		<li className={classes.listItem}>
			<span className={classes.listItemIcon}>
				<Done />
			</span>
			<span>{value}</span>
		</li>
	);
}

CustomListItem.propTypes = {
	value: PropTypes.string.isRequired,
};

CustomListItem.defaultProps = {
	value: '',
};
