// components
import ServiceCardV3 from '@/components/shared/servicesSection/partials/serviceCardV3';
// material
import { Box } from '@mui/material';
// Default
import get from 'lodash/get';
import PropTypes from 'prop-types';
// styles
import useStyles from './style';

export default function ServiceSectionV3({ cardList }) {
	const { classes } = useStyles();

	return (
		<Box className={classes.serviceV3Section}>
			{cardList.map((item, index) => {
				return (
					<Box key={index} className={classes.cardV3Wrap}>
						<ServiceCardV3
							{...item}
							iconImage={{
								url: get(item, 'iconImage.data.attributes.url', ''),
								alternativeText: get(
									item,
									'iconImage.data.attributes.alternativeText',
									''
								),
							}}
						/>
					</Box>
				);
			})}
		</Box>
	);
}

ServiceSectionV3.propTypes = {
	cardList: PropTypes.array,
};
ServiceSectionV3.defaultProps = {
	cardList: [],
};
