import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	anchorTag: {
		color: theme.palette.common.linkBlue,
		'&:hover': {
			textDecoration: 'underline',
		},
	},
}));

export default useStyles;
