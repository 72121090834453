import PropTypes from 'prop-types';

// style
import useStyles from './style';

export default function StyledTableTag(props) {
	const { classes, cx } = useStyles();

	const tableWrapClasses = cx(
		classes.tableWrap,
		props.tableStyled
			? classes.styledTableWrap
			: props.tableBorder
			? classes.tableBorder
			: ''
	);

	const tableClasses = cx(
		classes.table,
		props.rowBorder ? classes.tableRowBorder : '',
		props.columnBorder ? classes.tableColumnBorder : '',
		classes[`text_${props.tableTextAlignment}`]
	);
	return (
		<div className={tableWrapClasses}>
			<table className={tableClasses}>{props.children}</table>
		</div>
	);
}

StyledTableTag.propTypes = {
	tableStyled: PropTypes.bool,
	tableBorder: PropTypes.bool,
	rowBorder: PropTypes.bool,
	columnBorder: PropTypes.bool,
	tableTextAlignment: PropTypes.string,
};

StyledTableTag.defaultProps = {
	tableStyled: false,
	tableBorder: false,
	rowBorder: true,
	columnBorder: true,
	tableTextAlignment: 'left',
};
