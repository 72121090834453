import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	listItem: {
		display: 'flex',
		margin: theme.spacing(1, 0),
	},
	listItemIcon: {
		marginRight: theme.spacing(1.5),
	},
}));

export default useStyles;
