// Component
import ImageComponent from '@/components/widgets/image';
// Material
import { Box } from '@mui/material';
// Default
import PropTypes from 'prop-types';
import React from 'react';
// Styles
import useStyles from './style';

export default function CustomImage({ src, alt }) {
	const [imageWrapDimensions, setImageWrapDimensions] = React.useState({
		width: '100%',
		paddingTop: '41.667%',
	});

	const imageWrapRef = React.useRef(null);
	const { classes } = useStyles();

	const handleOnLoad = ({ target: { naturalWidth, naturalHeight } }) => {
		if (imageWrapRef.current.clientWidth > naturalWidth) {
			setImageWrapDimensions({ width: naturalWidth, height: naturalHeight });
			return;
		}

		setImageWrapDimensions({
			width: '100%',
			paddingTop: `calc(100% / (${naturalWidth} / ${naturalHeight})`,
		});
	};

	return (
		<Box
			ref={imageWrapRef}
			className={classes.imageWrapper}
			style={imageWrapDimensions}
		>
			<ImageComponent
				src={src}
				alt={alt}
				layout="fill"
				objectFit="contain"
				onLoad={handleOnLoad}
			/>
		</Box>
	);
}

CustomImage.propTypes = {
	src: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
};

CustomImage.defaultProps = {
	priority: false,
};
