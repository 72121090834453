import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	serviceV3Section: {
		display: 'flex',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
		},
	},
	cardV3Wrap: {
		margin: theme.spacing(2),
		flex: '0 0 calc(48% - 32px);',
		display: 'inline-block',
		[theme.breakpoints.down('md')]: {
			flex: '0 0 calc(100% - 32px);',
		},
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(2, 0),
			flex: '0 0 100%;',
		},
	},
}));

export default useStyles;
