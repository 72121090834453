import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { width, height }) => {
	const commonContainerStyles = {
		width: width || '100%',
		height: height || '100%',
	};

	const commonIconWrapStyles = {
		width: theme.spacing(8),
		height: theme.spacing(8),
		minWidth: theme.spacing(8),
		minHeight: theme.spacing(8),
		overflow: 'hidden',
		position: 'relative',
		borderRadius: '50%',
		backgroundColor: theme.palette.common.lightOrange,
	};

	return {
		container: {
			...commonContainerStyles,
			display: 'flex',
		},
		sectionOne: {
			width: '20%',
			marginRight: theme.spacing(2),
		},
		sectionTwo: {
			width: '80%',
		},
		iconWrap: {
			...commonIconWrapStyles,
			margin: '0 auto',
		},
		title: {
			fontWeight: theme.typography.fontWeightSemiBold,
		},
		mobileContainer: {
			...commonContainerStyles,
		},
		mobileSectionOne: {
			display: 'flex',
			alignItems: 'center',
		},
		mobileIconWrap: {
			...commonIconWrapStyles,
			marginRight: theme.spacing(2),
		},
	};
});

export default useStyles;
