import PropTypes from 'prop-types';

// style
import useStyles from './style';

export default function CustomAnchor({ anchorPoperties, children }) {
	const { classes } = useStyles();
	return (
		<a {...anchorPoperties} target="_blank" className={classes.anchorTag}>
			{children}
		</a>
	);
}

CustomAnchor.propTypes = {
	anchorPoperties: PropTypes.object,
};

CustomAnchor.defaultProps = {
	anchorPoperties: {},
};
