// components
import ImageComponent from '@/components/widgets/image';
import Markdown from '@/components/widgets/markdown';
// material
import { Box, Typography } from '@mui/material';
// Default
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// styles
import useStyles from './style';

export default function ServiceCardV3({ iconImage, ...props }) {
	const { classes } = useStyles(props);
	const isMobile = useSelector((state) => state.common.isMobile);

	if (isMobile) {
		return (
			<Box className={classes.mobileContainer}>
				<Box className={classes.mobileSectionOne}>
					<Box className={classes.mobileIconWrap}>
						{iconImage && iconImage.url && (
							<ImageComponent
								src={iconImage.url}
								alt={iconImage.alternativeText}
								layout="fill"
								objectFit="cover"
							/>
						)}
					</Box>
					<Box>
						<Typography variant="h6" className={classes.title}>
							{props.title}
						</Typography>
					</Box>
				</Box>

				<Box>
					<Markdown>{props.description}</Markdown>
				</Box>
			</Box>
		);
	}

	return (
		<Box className={classes.container}>
			<Box className={classes.sectionOne}>
				<Box className={classes.iconWrap}>
					{iconImage && iconImage.url && (
						<ImageComponent
							src={iconImage.url}
							alt={iconImage.alternativeText}
							layout="fill"
							objectFit="cover"
						/>
					)}
				</Box>
			</Box>
			<Box className={classes.sectionTwo}>
				<Box>
					<Typography component="h6" variant="h5" className={classes.title}>
						{props.title}
					</Typography>
				</Box>
				<Box>
					<Markdown>{props.description}</Markdown>
				</Box>
			</Box>
		</Box>
	);
}

ServiceCardV3.propTypes = {
	iconImage: PropTypes.object,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
};
ServiceCardV3.defaultProps = {
	iconImage: null,
};
